import React, { useState } from "react"
import { graphql } from "gatsby"

import Meta from "../components/addon/meta"
import Layout from "../components/layout/layout"
const Accreditations = ({
  data: {
    allNodeAccreditation: { edges },
    allNodeTemplatePages: { edges: bannerEdge },
  },
}) => {

  const metaTag = [];

  let [imgSrc, setImgSrc] = useState("")
  const [altText, setAltText] = useState("")
  let [modalData, setModalData] = useState({})

  const onCardClick = data => {
    if (data) {
      const image = data?.node?.relationships?.image?.uri?.url
      setImgSrc(image)
      const alt = data?.node?.image?.alt || "Hinduja Hospital"
      setModalData(data)
      setAltText(alt)
    } else setModalData({})
  }

  const awards = edges

  // const bannerData =
  //   Array.isArray(bannerEdge) && bannerEdge?.length > 0 && bannerEdge[0]
  // const bannerimg =
  //   typeof bannerData?.node?.relationships?.components[0]?.relationships
  //     ?.bannerImage?.uri?.url !== "undefined"
  //     ? bannerData?.node?.relationships?.components[0]?.relationships
  //         ?.bannerImage?.uri?.url
  //     : "/assets/images/patient_care/banner_patient_care.jpg"
  // const bannerAlt =
  //   typeof bannerData?.node?.relationships?.components[0]?.bannerImage?.alt !==
  //     "undefined" &&
  //   bannerData?.node?.relationships?.components[0]?.bannerImage?.alt?.length > 0
  //     ? bannerData?.node?.relationships?.components[0]?.bannerImage?.alt
  //     : "Hinduja Hospital"
  // const bannerTitle =
  //   typeof bannerData?.node?.relationships?.components[0]?.title !==
  //     "undefined" &&
  //   bannerData?.node?.relationships?.components[0]?.title?.length > 0
  //     ? bannerData?.node?.relationships?.components[0]?.title
  //     : ""
  // const bannerSubtitle =
  //   typeof bannerData?.node?.relationships?.components[0]?.subtitle !==
  //     "undefined" &&
  //   bannerData?.node?.relationships?.components[0]?.subtitle?.length > 0
  //     ? bannerData?.node?.relationships?.components[0]?.subtitle
  //     : ""

  return (
    <Layout>
      <Meta
        files={{
          js: [],
          css: [
            "/assets/css/services.css",
            "/assets/css/accessibility.css",
            "/assets/css/our-accreditation.css",
          ],
        }}
        tags={metaTag}
      />
      <main className="innerpage pt-first-section">
        {/* <section className="inner_common_banner">
          <picture className="banner_img">
            <img src={bannerimg} alt={bannerAlt} />
          </picture>
          <div className="banner_content text_left text_dark">
            <div className="container">
              <h1>{bannerTitle}</h1>
              <p>{bannerSubtitle}</p>
            </div>
          </div>
        </section> */}

        <section className="make_payment_section">
          <div className="container">
            <h2 className="section-heading text-left">Our Accreditations and Certifications</h2>
            <div className="payment_box_wrap">
              {Array.isArray(awards) &&
                awards.map((el, index) => {
                  const image = el?.node?.relationships?.image?.uri?.url
                  return (
                    <div className="box_sec" key={index}>
                      <span className="icon_image">
                        {image ? (
                          <img
                            src={image}
                            alt={
                              el?.node?.image?.alt?.length > 0
                                ? el?.node?.image?.alt
                                : `Accreditation Icon ${index}`
                            }
                          />
                        ) : (
                          <img
                            src="/assets/images/awards/award_pdq.jpg"
                            alt="sample_image"
                          />
                        )}
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            typeof el?.node?.description?.summary !==
                              "undefined" &&
                            el?.node?.description?.summary !== null
                              ? el?.node?.description?.summary
                              : "",
                        }}
                      />
                      <a
                        className="btn btn-primary-solid mt-2"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#modal_hinduja"
                        onClick={() => onCardClick(el)}
                      >
                        View More
                      </a>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </main>

      {Object.keys(modalData)?.length > 0 ? (
        <div
          className="modal fade modal_mgmt modal-center"
          id="modal_hinduja"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">X</span>
                </button>

                <figure className="bod_img">
                  <img src={imgSrc} alt={altText} />
                </figure>

                <div className="content_heading">
                  <h2>
                    {modalData?.node?.title
                      ? modalData?.node?.title
                      : "Hinduja Hospital"}
                  </h2>
                </div>

                <div className="content_desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        typeof modalData?.node?.description?.processed !==
                          "undefined" &&
                        modalData?.node?.description?.processed !== null
                          ? modalData?.node?.description?.processed
                          : "",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}
export const query = graphql`
  query AccreditationsPage {
    allNodeTemplatePages(
      filter: { path: { alias: { regex: "/our-accreditations$/" } } }
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphBannerworkss
            }
          }
        }
      }
    }
    allNodeAccreditation {
      edges {
        node {
          id
          title
          description: body {
            processed
            summary
          }
          image: field_desktop_image {
            alt
          }
          relationships {
            image: field_desktop_image {
              id
              uri {
                value
                url
              }
            }
          }
        }
      }
    }
  }
  fragment ParagraphBannerworkss on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
      alt
    }
    bannerLink: field_banner_link {
      uri
      title
    }
    relationships {
      bannerImage: field_banner_image {
        id
        uri {
          value
          url
        }
      }
    }
  }
`

export default Accreditations
